import React from "react";
import Nav from "./Nav";
import Projects from "./Projects";
import ContactForm from "./ContactForm";
import "../Style/main.css";

function Home() {
    
    return (
        <div id="outer-content-container">
            <header>
                <Nav />
            </header>
            <main>
                <section id="skills-container">
                    <h1>
                        Katrina A. Behnken
                    </h1>
                    <p>
                        Full Stack Web Developer<br /><br />
                    </p>
                    <p>
                        NodeJS | Express | MySQL | PostgreSQL | MongoDB | React | Vue | Javascript | HTML | CSS | Sass | Bootstrap | WordPress | Git
                    </p>
                    <hr />
                </section>
                <section>
                    <p>
                        Browse my projects below:<br /><br />
                    </p>
                    <Projects />
                    <hr />
                </section>
                <section>
                    <p>
                        If you need a full stack web developer to help you grow your business, I'd love to hear from you! Please complete and submit the form below to contact me:<br /><br />
                    </p>
                    <ContactForm />
                </section>
            </main>
            <footer style={{fontSize: "medium"}}>
                <br /><br />&copy; Copyright 2021, Katrina A. Behnken
            </footer>
        </div>
    )
}

export default Home;
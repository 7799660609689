import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi'

function Nav(props) {
    
    return(
        <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
                {/* <a className="navbar-brand" href="http://localhost:3000">My Portfolio</a> */}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon">
                    <GiHamburgerMenu style={{cursor: "pointer"}} />
                </span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" href="#skills-container">Skills</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#projects">Projects</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/assets/resume-210701.pdf" target=
                            "_blank">Résumé</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#contact">Contact Me</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav> 
    );
}

export default Nav;
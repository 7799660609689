import React, { useState } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from 'axios';

function ContactForm(props) {
    const [valid, setValid] = useState(false);
    const [fullName, setFullName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [msg, setMsg] = useState('');
    const handleVerify = function() {
        setValid(true)
    }
    const handleSubmit = function() {
        let msgHolder = document.getElementById('msg-holder');
        if (fullName && emailAddress && msg) {
            axios.post('https://katrinabehnken.com:4000/api/v1/send-emails', {
                fullName,
                emailAddress,
                msg
            })
            .then(() => {
                setFullName('');
                setEmailAddress('');
                setMsg('');
                msgHolder.style.color = 'green';
                msgHolder.innerHTML = 'Thank you for your submission. Your message was sent.';
            })
            .catch(err => {
                console.log(err)
            })
            console.log(valid)
        } else {
            msgHolder.style.color = 'red';
            msgHolder.innerHTML = 'All fields are required.';
        }
    }
    
    return(
        <section id="contact" className="contact">
            <h2>
                Contact Me
            </h2>
            <div id="msg-holder">

            </div>
            <div>
                <form className="contact-form">
                    <input required name="full-name" type="text" className="form-control" placeholder="* Full Name" value={fullName} onChange={e => setFullName(e.target.value)} /><br /><br />
                    <input required name="email" type="email" value={emailAddress} onChange={e => setEmailAddress(e.target.value)} className="form-control" placeholder="* Email Address" /><br /><br />
                    <textarea required name="msg" rows="5" value={msg} onChange={e => setMsg(e.target.value)} className="form-control" placeholder="* Message" /><br />
                    <GoogleReCaptcha onVerify={handleVerify} />
                    <div className="action-container">
                        <button type="button" onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>
                </form> 
            </div>
        </section>
    );
}

export default ContactForm;
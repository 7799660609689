import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Home from './Components/Home';

function App() {
  return (
    <div>
      <GoogleReCaptchaProvider reCaptchaKey='6LfsStEbAAAAAGRtgj7QE_kH1YFD68_MlRP3-k9D'>
        <Home />
      </GoogleReCaptchaProvider>
    </div>
  );
}

export default App;
